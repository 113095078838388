<template>
  <form 
    method="post"
    @submit.prevent="addValue"
    class="data-select"
    v-click-outside="{
      exclude: [],
      handler: 'hideDropdown'
    }"
  >
    <input 
      v-model="innerValue"
      :placeholder="label"
      type="text"
      class="data-select__input"
      @focus="showDropdown = true"
    />

    <div v-show="showDropdown" class="data-select__dropdown">
      <button 
        v-for="(option, i) in filteredOptions" 
        :key="i" 
        :class="{'selected': value.indexOf(option) > -1}"
        type="button"
        class="data-select__dropdown__item"
        @click="updateValue(option)"
      >
        {{ option.label }}
      </button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },

    options: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      innerValue: '',
      showDropdown: false,
    };
  },

  computed: {
    filteredOptions: {
      get() {
        return this.options.filter(option => {
          if (
            !this.innerValue.length || (this.innerValue.length && option.label.toLowerCase().indexOf(this.innerValue.toLowerCase()) > -1)
          ) {
            return option;
          }
        });
      }
    },
  },

  methods: {
    updateValue(value) {
      if (this.value.indexOf(value) > -1) {
        this.$emit('remove', value);
        this.hideDropdown();

        return;
      }

      this.$emit('add', value);
      this.hideDropdown();
    },

    addValue(e) {
      e.preventDefault();

      this.$emit('add', this.innerValue);     
      this.$nextTick(() => {
        this.innerValue = '';
      });
    },

    hideDropdown() {
      this.showDropdown = false;
      this.$nextTick(() => {
        this.innerValue = '';
      }) 
    }
  }
}
</script>