<template>
  <div class="calendar__day">
    <slot/>

    <div v-if="filteredEvents.length">
      <div
        v-for="(event, id) in filteredEvents"
        :key="id"
        :class="[
          'calendar__entry',
          'calendar__entry--list',
          {'calendar__entry--unique': filter},
          {'calendar__entry--clickable': open},
        ]"
        @click="edit(event)"
      >
        <div class="calendar__entry__hour">
          <p>
            {{ event.hour || 'Non défini' }}
          </p>
        </div>
        <div class="calendar__entry__location">
          <div v-if="showUser">
            <p class="calendar__user" v-for="(user, idUser) in event.users" :key="idUser">
              <span v-if="user.color" :class="[
                'calendar__color',
                `calendar__color--${user.color.value}`,
              ]"></span>
              {{ name(user) }}
            </p>
          </div>

          <div v-if="showBicycle">
            <p class="calendar__user">
              <span :class="[
                'calendar__color',
                `calendar__color--${colorBicycle(event.bicycle)}`,
              ]"></span>
              {{ event.bicycle }}

              <span v-for="(user, idUser) in event.users" :key="idUser">
                {{ name(user) }}
              </span>
            </p>
          </div>

          <p v-html="filterAddress(event.location || 'address, address')"></p>
        </div>

        <div class="calendar__entry__action" v-if="action && showLateButton(event)">
          <!-- <button
            class="btn btn--primary"
            @click="sendClassic(event, id)"
          >
            Démarrer 
            <span v-if="clientsCount(event.location)">
              ({{ clientsCount(event.location) }})
            </span>
          </button>

          <button
            class="btn btn--warning"
            @click="skip(event, id)"
          >
            Passer
          </button> -->

          <button
            v-if="showLateButton(event)"
            class="btn btn--danger btn--big"
            @click="sendLate(event, id)"
          >
            En retard
          </button>
        </div>
      </div>
    </div>

    <div v-else>
      <p>Pas de créneau enregistré</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    events: {
      type: Array,
      default: () => [],
    },

    action: {
      type: Boolean,
      default: false,
    },

    filter: Boolean,
    showUser: Boolean,
    showBicycle: Boolean,
    open: Boolean,
  },

  computed: {
    ...mapGetters({
      clients: 'clients/getClientByLocation',
    }),

    filteredEvents() {
      return this.filter ? [this.events.filter(e => !e.sent)[0]] || [] : this.events;
    },
  },

  methods: {
    filterAddress(address) {
      return address.split(',').filter(a => a !== ' France').join(' - ');
    },

    clientsCount(e) {
      return this.clients[e] && this.clients[e].length;
    },

    receivers(event) {
      return [... new Set(this.clients[event.location].map(c => c.tel))];
    },

    sendClassic(event, id) {
      if (!this.clientsCount(event.location)) return;

      const receivers = this.receivers(event);

      this.$emit('alert', { event, id, receivers });

      window.open(`https://maps.google.com/maps?daddr=${event.location}`);
    },

    sendLate(event, id) {
      if (!this.clientsCount(event.location)) return;
      
      const receivers = this.receivers(event);

      this.$emit('late', { event, id, receivers });
    },

    skip(event, id) {
      this.$emit('skip', { event, id });
    },

    name({ firstname, lastname }) {
      return (firstname && `${firstname} ${lastname && lastname.slice(0, 1) || ''}.`) || 'Nullos';
    },

    edit(e) {
      if (!this.open) return;

      this.$emit('open', e);
    },

    colorBicycle(bicycle) {
      if (bicycle === 'Velo 1') return 'green-d';
      if (bicycle === 'Velo 2') return 'blue-d';

      return 'purple';
    },

    showLateButton(event) {
      return this.clientsCount(event.location) > 0 && !event.late;
    },
  } ,
};
</script>
