<template>
  <transition name="fade">
    <div class="component-toaster" v-if="toaster.length > 0">
      <transition-group name="toaster" class="toaster" tag="div">
        <div
          class="toaster__item"
          v-for="toast in toaster"
          :key="toast.time"
          :class="{ 'toaster__item--error' : toast.type === 'error'}"
          @click="clearToaster(toast.time)"
        >
          <div class="toaster__text">
            <p v-html="toast.message"></p>
          </div>
        </div>
      </transition-group>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
export default {
  mounted() {
    this.checkToasterLifecycle();
  },
  updated() {
    this.checkToasterLifecycle();
  },
  computed: {
    ...mapGetters('toaster', ['getToaster']),
    toaster() {
      return this.getToaster || [];
    },
  },
  methods: {
    ...mapActions('toaster', ['clearToaster']),
    checkToasterLifecycle() {
      if (this.getToaster) {
        _.forEach(this.getToaster, (toast) => {
          if (toast.time > Date.now() + 10) {
            return this.clearToaster(toast.time);
          }
          window.setTimeout(() => {
            this.clearToaster(toast.time);
          }, 10000);
        });
      }
    },
  },
};
</script>