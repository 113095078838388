import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import '@/misc/register-service-worker';
import '@/misc/handle-network-status';
import '@/firebase/init';
import '@/firebase/authentication';
import '@/misc/handle-apple-install-prompt';
import 'pwacompat';
import ClickOutside from '@/directives/click-outside';

import './theme/app.scss';

Vue.directive('click-outside', ClickOutside);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
