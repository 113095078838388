var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar__day"},[_vm._t("default"),(_vm.filteredEvents.length)?_c('div',_vm._l((_vm.filteredEvents),function(event,id){return _c('div',{key:id,class:[
        'calendar__entry',
        'calendar__entry--list',
        {'calendar__entry--unique': _vm.filter},
        {'calendar__entry--clickable': _vm.open},
      ],on:{"click":function($event){return _vm.edit(event)}}},[_c('div',{staticClass:"calendar__entry__hour"},[_c('p',[_vm._v(" "+_vm._s(event.hour || 'Non défini')+" ")])]),_c('div',{staticClass:"calendar__entry__location"},[(_vm.showUser)?_c('div',_vm._l((event.users),function(user,idUser){return _c('p',{key:idUser,staticClass:"calendar__user"},[(user.color)?_c('span',{class:[
              'calendar__color',
              `calendar__color--${user.color.value}`,
            ]}):_vm._e(),_vm._v(" "+_vm._s(_vm.name(user))+" ")])}),0):_vm._e(),(_vm.showBicycle)?_c('div',[_c('p',{staticClass:"calendar__user"},[_c('span',{class:[
              'calendar__color',
              `calendar__color--${_vm.colorBicycle(event.bicycle)}`,
            ]}),_vm._v(" "+_vm._s(event.bicycle)+" "),_vm._l((event.users),function(user,idUser){return _c('span',{key:idUser},[_vm._v(" "+_vm._s(_vm.name(user))+" ")])})],2)]):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(_vm.filterAddress(event.location || 'address, address'))}})]),(_vm.action && _vm.showLateButton(event))?_c('div',{staticClass:"calendar__entry__action"},[(_vm.showLateButton(event))?_c('button',{staticClass:"btn btn--danger btn--big",on:{"click":function($event){return _vm.sendLate(event, id)}}},[_vm._v(" En retard ")]):_vm._e()]):_vm._e()])}),0):_c('div',[_c('p',[_vm._v("Pas de créneau enregistré")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }