import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const dataState = createPersistedState({
  storage: localStorage,
  paths: ['clients', 'users'],
});

const appState = createPersistedState({
  storage: sessionStorage,
  paths: ['authentication', 'toaster', 'googleAgenda', 'text'],
});

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [dataState, appState],
  modules,
});
