import Vue from 'vue';
import Router from 'vue-router';
import Head from 'vue-head';
import Home from '@/views/Home';
import CheckLogin from '@/views/CheckLogin';
import store from '@/store';

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/Calendar.vue'),
      meta: {
        allowed: ['ROLE_ADMIN'],
      }
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/Products.vue'),
      meta: {
        allowed: ['ROLE_ADMIN'],
      }
    },
    {
      path: '/alerte',
      name: 'alert',
      component: () => import('@/views/Alert.vue'),
      // meta: {
      //   allowed: ['ROLE_USER', 'ROLE_ADMIN'],
      // }
      meta: {
        authNotRequired: true
      },
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('@/views/Clients.vue'),
      meta: {
        allowed: ['ROLE_USER', 'ROLE_ADMIN'],
      }
    },
    {
      path: '/equipe',
      name: 'team',
      component: () => import('@/views/Users.vue'),
      meta: {
        allowed: ['ROLE_ADMIN'],
      }
    },
    { path: '*', redirect: '/login' }
  ]
})

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (
    !(to.meta && to.meta.authNotRequired) &&
    (
      !store.getters['authentication/isUserLoggedIn'] ||
      !store.getters['authentication/isUserCertified']
    )
  ) {
    const path = store.getters['authentication/isUserLoggedIn'] ? '/login' : '/check-login';
    return next(`${path}?redirectUrl=${to.path}`);
  }

  if (
    to.meta &&
    to.meta.allowed &&
    !to.meta.allowed.find(role => store.getters['authentication/userRoles'].includes(role))) {
    return next({ name: 'home' });
  }

  next();
})

export default router
