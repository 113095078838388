const state = {
  toaster: [],
};

const getters = {
  getToaster: state => state.toaster,
};

const mutations = {
  handleToaster: (state, toast) => {
    state.toaster.push(toast);
  },

  clearToaster: (state, time) => {
    state.toaster = state.toaster.filter(e => e.time !== time);
  },

  clearToasters: (state) => {
    state.toaster = [];
  },
};

const actions = {
  setToaster: ({ commit }, toast) => {
    commit('handleToaster', toast);
  },
  clearToaster: ({ commit }, time) => {
    commit('clearToaster', time);
  },
  clearToasters: ({ commit }) => {
    commit('clearToasters');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
