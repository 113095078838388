import Clients from '@/firebase/clients-db';
const clientsDb = new Clients();

const state = {
  clients: [],
}

const getters = {
  getClients: state => state.clients,
  getClientByLocation: state => {
    const locations = {};
    state.clients.map(client => {
      client.locations.map(geo => {
        if (!locations[geo]) locations[geo] = [];

        locations[geo].push({
          name: client.name,
          tel: client.tel,
        });
      });
    });
    
    return locations;
  },
}

const mutations = {
  addClient: (state, client) => {
    state.clients.push(client);
  },

  setClients: (state, clients) => {
    state.clients = clients;
  },

  removeClients: (state) => {
    state.toaster = [];
  },
}

const actions = {
  addClient: ({ commit }, client) => {
    commit('addClient', client);
  },
  setClients: async ({ commit }) => {
    try {
      const clients = await clientsDb.readAll();

      commit('setClients', clients);
    } catch (error) {
      window.console.log(error);
    }
  },
  removeClients: ({ commit }) => {
    commit('removeClients');
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
