/* eslint-disable */

import { isNil } from 'lodash';
import Users from '@/firebase/users-db';
import { dateHelper } from '@/misc/moment.js';

const usersDb = new Users();

const state = {
  users: [],
  roles: [
    { value: 'ROLE_ADMIN', label: 'Boss'},
    { value: 'ROLE_USER', label: 'Cycliste'},
  ],
  bicycles: [
    'Velo 1',
    'Velo 2',
  ],
};

const getters = {
  getUsers: state => state.users,
  getUserById: state => id => state.users.find(user => user.id === id),
  availableRoles: state => state.roles,
  getUsersByRole: state => role => state.users.filter(user => {
    return (
      user.active &&
      user.roles.some(r => r.value === role)
    );
  }),
  availableBicycles: state => state.bicycles,
  getUserByBicycle: state => (bicycle) => state.users.filter(user => {
    return (
      user.bicycle === bicycle &&
      user.active &&
      user.roles.some(role => role.value === 'ROLE_USER')
    );
  }),
  getUserByBicycleWithDate: state => (bicycle, date = undefined) => state.users.filter(user => {
    const isEvenWeekNumber = !!date && !!(dateHelper(date, 'DD/MM/YYYY').isoWeek() % 2);

    return (
      (
        (
          user.leader &&
          (isEvenWeekNumber && user.bicycle !== bicycle) ||
          (!isEvenWeekNumber && user.bicycle === bicycle) 
        ) ||
        (
          !user.leader &&
          user.bicycle === bicycle
        )
      ) &&
      user.active &&
      user.roles.some(role => role.value === 'ROLE_USER')
    );
  }),
  availableUsers: state => state.users.filter(user => !isNil(user.bicycle) && user.active),
};

const mutations = {
  setUsers: (state, users) => {
    state.users = users;
  },

  addUser: (state, user) => {
    state.users.push(user);
  },

  updateUser: (state, { user, index }) => {
    state.users[index] = Object.assign({}, user);
  }
};

const actions = {
  setUsersStore: async ({ commit }) => {
    try {
      const users = await usersDb.readAll();

      commit('setUsers', users);
    } catch (error) {
      console.log(error);
    }
  },

  createUser: async ({ commit }, user) => {
    try {
      const newUser = await usersDb.create(user);

      commit('addUser', newUser);
    } catch (error) {
      console.log(error);
    }
  },

  updateUser : async ({ commit, state }, user) => {
    try {
      const index = state.users.findIndex(u => u.id === user.id);
      await usersDb.update(user);

      commit('updateUser', { user, index });
    } catch (error) {
      console.log(error);
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
