import router from '@/router';
import { isNil } from 'lodash';
import { createNewUserFromFirebaseAuthUser } from '@/misc/helpers';
import UsersDB from '@/firebase/users-db';

const state = {
  user: undefined,
  error: undefined,
  authorized: false,
};

const getters = {
  user: state => state.user,
  isUserLoggedIn: state => !isNil(state.user),
  isUserCertified: state => state.user && state.user.active,
  getUserError: state => state.error,
  isGoogleApiAuthorized: state => state.authorized,
  userRoles: state => state.user && state.user.roles.map(r => r.value),
  userBicycle: state => state.user && state.user.bicycle,
};

const mutations = {
  setUser: (state, value) => (state.user = value),
  clearError: (state) => state.error = undefined,
  setError: (state, value) => state.error = value,
  setGoogleApiAuthorization: (state, value) => state.authorized = value,
};

const actions = {
  login: async ({ commit }, firebaseAuthUser) => {
    commit('clearError')
    const userFromFirebase = await new UsersDB().read(firebaseAuthUser.uid)

    const user = isNil(userFromFirebase)
      ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser)
      : userFromFirebase;
  
    if (isNil(user)) {
      commit('setError', 'User not found')
      return;
    }

    commit('setUser', user)
  },

  logout: ({ commit }) => {
    commit('setUser', null)

    const currentRouter = router.app.$route
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push('/login')
    }
  },

  authorize: ({ commit }, value) => {
    commit('setGoogleApiAuthorization', value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
