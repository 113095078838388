import UsersDB from '@/firebase/users-db';

// eslint-disable-next-line
export const createNewUserFromFirebaseAuthUser = async firebaseAuthUser => {
  const providerData = firebaseAuthUser.providerData[0];
  const { displayName, photoURL, email } = providerData;
  const userDb = new UsersDB();
  const user = {
    displayName,
    photoURL,
    email,
  };

  return userDb.create(user, firebaseAuthUser.uid)
}
