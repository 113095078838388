import { dateHelper } from '@/misc/moment.js';
import { groupBy, sortBy } from 'lodash';
import Events from '@/firebase/events-db';

const eventsDb = new Events();

/* global gapi */

const state = {
  calendars: [
    {
      calendarId: 'ccavrac@gmail.com',
      name: 'Comment ça vrac Vélo 1 - Demande',
      bicycle: 'Velo 1',
      main: false,
      events: [],
    },
    {
      calendarId: 'ipt0ii4ash2jrqqd361n0svp7g@group.calendar.google.com',
      name: 'Comment ça vrac Vélo 1 - Fixe',
      bicycle: 'Velo 1',
      main: true,
      events: [],
    },
    {
      calendarId: 'ccavrac2@gmail.com',
      name: 'Comment ça vrac Vélo 2 - Demande',
      bicycle: 'Velo 2',
      main: false,
      events: [],
    },
    {
      calendarId: '9mha7v6n49t2fnc20mlkli5rjo@group.calendar.google.com',
      name: 'Comment ça vrac Vélo 2 - Fixe',
      bicycle: 'Velo 2',
      main: true,
      events: [],
    },
  ],
  authorized: false,
};

const getters = {
  calendars: state => state.calendars,
  isGoogleApiAuthorized: state => state.authorized,
};

const mutations = {
  setGoogleApiAuthorization: (state, value) => state.authorized = value,
  fillCalendar: (state, { index, events }) => {
    const id = state.calendars.findIndex(c => c.calendarId === index);

    state.calendars[id].events = events;
  },
};

const actions = {
  handleClientLoad: ({ commit, state }) => {
    const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
    const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';

    try {
      gapi.load('client:auth2', async function() {
        await gapi.client.init({
          apiKey: process.env.VUE_APP_API_KEY,
          clientId: process.env.VUE_APP_CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES
        });
        
        gapi.auth2.getAuthInstance().isSignedIn.listen(state.authorized);
      });
    } catch(err) {
      commit('setGoogleApiAuthorization', false);
    }
  },

  handleAuthClick: async ({ commit }) => {
    try {
      await gapi.auth2.getAuthInstance().signIn();
      
      commit('setGoogleApiAuthorization', true);
    } catch(err) {
      commit('setGoogleApiAuthorization', false);
    }
  },

  fetchData: async ({ commit, state, dispatch }) => {
    const today = new Date();
    today.setHours(0, 0, 0);

    try {
      for (const calendar of state.calendars) {
        commit('fillCalendar', {
          index: calendar.calendarId,
          events: [],
        });

        const { result } = await gapi.client.calendar.events.list({
          'calendarId': calendar.calendarId,
          'timeMin': today.toISOString(),
          'timeMax': (new Date(today.getTime() + 12 * 24 * 60 * 60 * 1000)).toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 150,
          'orderBy': 'startTime'
        });

        const events = await result.items.map((event) => {
          event.date = (new Date(event.start.dateTime)).toLocaleString().split(' ')[0];
          event.calendarId = calendar.calendarId;
          event.idDate = (new Date(event.start.dateTime)).toLocaleString().split(' ')[0].split('/').reverse().join('');

          const isDeclined = event.attendees && event.attendees.filter(attendee => attendee.email === calendar.calendarId && attendee.responseStatus === 'declined').length;

          if (!event.transparency && event.location && !isDeclined) return event;
        });

        const groupedEvents = groupCalendar({
          collection: events.filter(e => e !== undefined),
          bicycle: calendar.bicycle,
          main: calendar.main,
        });

        await commit('fillCalendar', {
          index: calendar.calendarId,
          events: groupedEvents,
        });
      }
    } catch (error) {
      dispatch('toaster/setToaster', {
        message: error,
        type: 'error',
        time: Date.now(),
      }, { root: true });
    }
  },

  syncEvents: async ({ dispatch, state }) => {
    try {
      for (let i=0; i < state.calendars.length; i++) {
        state.calendars[i].events.forEach(async event => {
          const response = await eventsDb.read(`${event.idDate}-${event.calendarId}`);
      
          if (!response) {
            if (event.idDate) {
              await eventsDb.create({...event}, `${event.idDate}-${event.calendarId}`);
            }
          } else {
            const updatedEvent = event.events.map(e => {
              const existingEvent = response.events.find(ev => ev.id === e.id);
              
              if (existingEvent) {
                Object.assign(existingEvent, { ...e });
              } 
      
              return existingEvent || e;
            });

            response.events = updatedEvent;
      
            await eventsDb.update({...response});
          }
        });
      }

      // dispatch('calendar/assignUsers', null, { root: true });

      dispatch('toaster/setToaster', {
        message: `L'agenda a été synchronisé`,
        type: 'message',
        time: Date.now(),
      }, { root: true });
    } catch(error) {
      dispatch('toaster/setToaster', {
        message: error,
        type: 'error',
        time: Date.now(),
      }, { root: true });
    }
  },
};

function groupCalendar({ collection, bicycle, main }) {
  const result = [];

  const days = groupBy(sortBy(collection, e => dateHelper(e.date, 'DD/MM/YYYY')), 'date');
  let lastDate = dateHelper();

  Object.entries(days).forEach(([index, day], i) => {
    const eventDate = dateHelper(index, 'DD/MM/YYYY');
    const diffDays = Math.ceil(eventDate.diff(lastDate, 'days', true));

    if (diffDays >= 1) {
      // const lastDateDay = lastDate.format('dddd').toString();
      if (i === 0) {
        result.push({
          day: lastDate.format('dddd').toString(),
          date: lastDate.format('DD/MM/YYYY').toString(),
          events: [],
        });
      }

      for (let j = (diffDays - 1); j > 0; j--) {
        const missingDay = dateHelper(index, 'DD/MM/YYYY').subtract(j, 'days');
        const dayToAdd = missingDay.format('dddd').toString();

        result.push({
          day: dayToAdd,
          date: missingDay.format('DD/MM/YYYY').toString(),
          events: [],
        });
      }
    }

    lastDate = eventDate;

    result.push({
      day: dateHelper(day[0].start.dateTime).format('dddd'),
      date: eventDate.format('DD/MM/YYYY').toString(),
      idDate: eventDate.format('YYYYMMDD').toString(),
      calendarId: day[0].calendarId,
      bicycle,
      events: day.map(d => {
        return {
          ...d,
          hour: dateHelper(d.start.dateTime).format('HH:mm'),
          endHour: dateHelper(d.end.dateTime).format('HH:mm'),
          isMain: main,
          bicycle,
        };
      })
    });
  });

  return result;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
