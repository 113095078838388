<template>
  <transition name="modal">
    <div class="modal-mask" @click="clickOutside">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer row">
            <slot name="footer">
              <button class="btn" @click="$emit('close')">
                Fermer
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    clickOutside(e) {
      if (e.target.className !== 'modal-mask' && e.target.className !== 'modal-wrapper') return;
      this.$emit('close', e);
    },
  },
};
</script>
