import app from './app';
import authentication from './authentication';
import toaster from './toaster';
import clients from './clients';
import users from './users';
import calendar from './calendar';
import googleAgenda from './googleAgenda';
import text from './text';

export default {
  app,
  authentication,
  toaster,
  clients,
  users,
  calendar,
  googleAgenda,
  text,
};
