import { dateHelper } from '@/misc/moment.js';
import Events from '@/firebase/events-db';
import { groupBy, sortBy } from 'lodash';

const eventsDb = new Events();

const state = {
  rawEvents: [],
  events: [],
};

const getters = {
  rawEvents: state => state.rawEvents,
  events: state => state.events,
  eventsOrdered: state => date => {
    const events = state.events.find(e => e.date === date);

    return events ? groupBy(sortBy(events.events, e => e.hour), 'hour') : [];
  },
  eventsByEventId: state => ({ idDate, calendarId }) => {
    const rawCalendarId = state.rawEvents.findIndex(raw => {
      return raw.idDate === idDate && raw.calendarId === calendarId
    });

    return state.rawEvents[rawCalendarId];
  },
  eventsByUserId: state => ({ user, date }) => {
    const events = state.events.find(e => e.date === date);

    if (!events || !user) return [];

    return sortBy(events.events, e => e.hour);

    // return sortBy(events.events, e => e.hour).filter(e => {
    //   return e.users && e.users.length && e.users.filter(u => {
    //     return u.id === user.id;
    //   }).length;
    // });
  },
};

const mutations = {
  resetEvents: (state) => {
    state.events = [];
    state.rawEvents = [];
  },

  addEvent: (state, event) => {
    state.events.push(event);
  },

  setEvents: (state, events) => {
    state.events = events;
  },

  setRawEvents: (state, events) => {
    state.rawEvents = events;
  },

  updateEvent: (state, event) => {
    const calendarId = state.events.findIndex(ev => {
      return ev.idDate === event.idDate
    });

    const eventId = state.events[calendarId].events.findIndex(e => e.id === event.id);

    state.events[calendarId].events.splice(eventId, 1, event);
  },

  updateRawEvent: (state, event) => {
    const rawCalendarId = state.rawEvents.findIndex(raw => {
      return raw.idDate === event.idDate && raw.calendarId === event.calendarId
    });

    const rawEventId = state.rawEvents[rawCalendarId].events.findIndex(e => e.id === event.id);

    state.rawEvents[rawCalendarId].events.splice(rawEventId, 1, event);
  },
};

const actions = {
  fetchEvents: async ({ commit }) => {
    commit('resetEvents');
    const d = dateHelper().format('YYYYMMDD').toString();
    const collection = [];

    const events = await eventsDb.readAll([
      ['idDate', '>=', d],
    ]);

    await commit('setRawEvents', JSON.parse(JSON.stringify(events)));
    
    events.map((e, index) => {
      if (index === 0) return collection.push(e);

      if (collection[collection.length - 1].idDate === e.idDate) {
        collection[collection.length - 1].events.push(...e.events);
        return;
      }

      collection.push(e);
    });

    await commit('setEvents', collection);
  },

  assignUsers: async ({ dispatch, state, getters, rootGetters, commit }) => {
    try {
      if (!state.events.length) return;
  
      for (const day of state.events) {
        const eventsByDate = getters.eventsOrdered(day.date);

        for (const event of Object.values(eventsByDate)) {
          const eventsByBicycle = groupBy(event, 'bicycle');

          
          for (const events of Object.values(eventsByBicycle)) {
            
            for (const e of events) {
              if (!e.users || (e.users && !e.users.length)) {
                const users = events.length > 1 ? rootGetters['users/getUserByBicycleWithDate'](e.bicycle, e.date).filter(user => e.isMain ? user.leader : !user.leader) : rootGetters['users/getUserByBicycleWithDate'](e.bicycle, e.date);
    
                const eventFilled = Object.assign({}, e, { users });
      
                await commit('updateEvent', eventFilled);
                await commit('updateRawEvent', eventFilled);
              }
            }
          }
        }
      }

      state.rawEvents.forEach(async event => {
        if (event.id) await eventsDb.update({...event});
      });

      dispatch('toaster/setToaster', {
        message: `Les cyclistes ont bien été assignés`,
        type: 'message',
        time: Date.now(),
      }, { root: true });
    } catch(error) {
      dispatch('toaster/setToaster', {
        message: error,
        type: 'error',
        time: Date.now(),
      }, { root: true });
    }
  },

  updateEvent: async ({ commit, dispatch, getters }, event) => {
    try {
      await commit('updateEvent', event);
      await commit('updateRawEvent', event);
  
      if (event.id) {
        await eventsDb.update({...getters.eventsByEventId(event)});
      }

      dispatch('toaster/setToaster', {
        message: `L'événement a bien été mis à jour`,
        type: 'message',
        time: Date.now(),
      }, { root: true });

      return {...getters.eventsByEventId(event)};
    } catch(error) {
      dispatch('toaster/setToaster', {
        message: error,
        type: 'error',
        time: Date.now(),
      }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
