import { dateHelper } from '@/misc/moment.js';
import Text from '@/firebase/text-db';
const textDb = new Text();

const state = {
  text: [],
}

const getters = {
  getText: state => state.text,
}

const mutations = {
  setText: (state, text) => {
    state.text = text;
  },

  removeText: (state) => {
    state.text = [];
  },
}

const actions = {
  fetchTodayText: async ({ commit }) => {
    try {
      const today = dateHelper().format('YYYYMMDD').toString();
      const response = await textDb.read(today);

      commit('removeText');

      if (response && response.notifications) {
        commit('setText', response.notifications);
      }
    } catch (error) {
      window.console.log(error);
    }
  },
  removeText: ({ commit }) => {
    commit('removeText');
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
