<template>
<div>
  <form 
    method="post" 
    class="form" 
    ref="mainForm"
    @submit.prevent="submit"
  >
    <p class="title title--3">
      <span>Mettre à jour l'événement</span>
    </p>

    <p>{{ innerEvent.location }}</p>

    <fieldset class="form__field">
      <label class="form__label">Cyclistes</label>

      <ul class="form__button-container">
        <li v-for="(user, i) in innerEvent.users" :key="i">
          {{ user.firstname }}

          <button 
            class="btn btn--danger btn--small"
            type="button"
            @click="removeElement('users', user)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </li>
      </ul>
    
      <DataSelectObject
        label="Ajouter un cycliste"
        :value="innerEvent.users"
        :options="labelledUsers"
        @add="addElement('users', $event)"
        @remove="removeElement('users', $event)"
      />
    </fieldset>
    
    <button class="btn btn--primary">
      Sauvegarder
    </button>
  </form>

</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DataSelectObject } from '@/components/Common';

export default {
  components: { DataSelectObject },

  props: {
    event: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  data() {
    return {
      innerEvent: {},
    };
  },

  computed: {
    ...mapGetters({
      users: 'users/getUsersByRole',
      users2: 'users/getUsers',
    }),

    labelledUsers() {
      return this.users('ROLE_USER').map(user => {
        return Object.assign(user, { label: this.name(user) });
      });
    }
  },

  watch: {
    event: {
      immediate: true,
      nested: true,
      handler() {
        this.innerEvent = JSON.parse(JSON.stringify(this.event));
      }
    }
  },

  methods: {
    submit(e) {
      e.preventDefault();

      if (this.innerEvent.id) return this.$emit('save', this.innerEvent);
    },

    addElement(key, elem) {
      if (!elem) return;

      if (!this.innerEvent[key]) {
        this.$set(this.innerEvent, key, []);
      }

      this.$nextTick(() => {
        this.innerEvent[key].push(elem);
      });
    },

    removeElement(key, elem) {
      const index = this.innerEvent[key].indexOf(elem);
      this.innerEvent[key].splice(index, 1);
    },

    name({ firstname, lastname }) {
      return (firstname && `${firstname} ${lastname && lastname.slice(0, 1) || ''}.`) || 'Nullos';
    },
  },
};
</script>