import { isNil } from 'lodash'

const state = {
    appTitle: process.env.VUE_APP_TITLE,
    appShortTitle: process.env.VUE_APP_SHORT_TITLE,
    networkOnLine: true,
    SWRegistrationForNewContent: null,
    showAddToHomeScreenModalForApple: false,
    refreshingApp: false,
    baseurl: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api.commentcavrac.com',
}

const getters = {
  newContentAvailable: state => !isNil(state.SWRegistrationForNewContent),
  baseurl: state => state.baseurl,
}

const mutations = {
  setNetworkOnline: (state, value) => (state.networkOnLine = value),
  setSWRegistrationForNewContent: (state, value) =>
    (state.SWRegistrationForNewContent = value),
  setShowAddToHomeScreenModalForApple: (state, value) =>
    (state.showAddToHomeScreenModalForApple = value),
  setRefreshingApp: (state, value) => (state.refreshingApp = value)
}


const actions = {
  closeAddToHomeScreenModalForApple: async ({ commit }) => {
    localStorage.setItem('addToHomeIosPromptLastDate', Date.now())
    commit('setShowAddToHomeScreenModalForApple', false)
  },

  serviceWorkerSkipWaiting({ state, commit }) {
    if (isNil(state.SWRegistrationForNewContent)) return

    commit('setRefreshingApp', true)
    state.SWRegistrationForNewContent.waiting.postMessage('skipWaiting')
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}