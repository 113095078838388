<template>
  <div class="page-wrapper">
    <div class="infobox">
      <div class="infobox__box">
        <div class="infobox__title">
          SMS planifiés aujourd'hui
        </div>

        <div class="infobox__content">
          {{ textSent }}
        </div>
      </div>
    </div>

    <div id="calendar">
      <div class="calendar__title-container">
        <h1 class="calendar__container__title">
          <span class="calendar__container__title__content">{{ day }}</span>

          <span class="calendar__container__title__date">
            {{ date.slice(0, 5) }}
          </span>

          <span
            :class="
              `calendar__container__title__bg calendar__container__title__bg--${day}`
            "
          ></span>
        </h1>

        <p class="calendar__container__sub-title" v-html="corner[day]"></p>
      </div>

      <div class="admin__buttons">
        <button class="btn" @click="previousDay">
          &lsaquo; Jour précédent
        </button>
        <button class="btn" @click="nextDay">Jour suivant &rsaquo;</button>
        <button class="btn" @click="assignUsers">Répartir les cyclistes</button>
        <button class="btn" @click="screenshot">Capture d'écran</button>
      </div>

      <div>
        <div v-for="(event, id) in events(date)" :key="id">
          <CalendarItemList
            :events="event"
            class="calendar-list"
            @open="edit"
            open
            showBicycle
          ></CalendarItemList>
        </div>
      </div>
    </div>

    <Modal v-if="showModal" @close="close">
      <div slot="body">
        <FormEvent :event="event" @save="update" @error="error" />
      </div>
    </Modal>

    <Toaster />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import html2canvas from 'html2canvas'
import { dateHelper } from '@/misc/moment.js'
import CalendarItemList from '@/components/Calendar/CalendarItemList'
import { Toaster, Modal } from '@/components/Utils'
import { FormEvent } from '@/components/Events'

export default {
  components: { CalendarItemList, Toaster, Modal, FormEvent },

  data() {
    return {
      today: dateHelper(),
      showModal: false,
      event: {},
      corner: {
        lundi:
          '&bull;&nbsp;Lille-Sud &bull;&nbsp;Faches <br />&bull;&nbsp;Ronchin',
        mardi:
          '&bull;&nbsp;Vauban Esquermes &bull;&nbsp;Bois Blancs <br />&bull;&nbsp;Fg Béthune &bull;&nbsp;Lomme &bull;&nbsp;Lambersart',
        mercredi:
          '&bull;&nbsp;La Madeleine &bull;&nbsp;Saint André <br />&bull;&nbsp;St Maurice Pellevoisin',
        jeudi:
          '&bull;&nbsp;Hellemmes &bull;&nbsp;Fives <br />&bull;&nbsp;Mons-en-Baroeul',
        vendredi:
          '&bull;&nbsp;Wazemmes &bull;&nbsp;Moulins <br />&bull;&nbsp;Lille-centre &bull;&nbsp;Vieux-Lille'
      }
    }
  },

  created() {
    this.fetchEvents()
    this.fetchText()
  },

  computed: {
    ...mapGetters({
      events: 'calendar/eventsOrdered',
      text: 'text/getText'
    }),

    date() {
      return dateHelper(this.today)
        .format('DD/MM/YYYY')
        .toString()
    },

    day() {
      return dateHelper(this.today)
        .format('dddd')
        .toString()
    },

    textSent() {
      return this.text.reduce((acc, text) => acc + text.receivers.length, 0)
    }
  },

  methods: {
    ...mapActions({
      fetchEvents: 'calendar/fetchEvents',
      assignUsers: 'calendar/assignUsers',
      updateEvent: 'calendar/updateEvent',
      fetchText: 'text/fetchTodayText'
    }),

    previousDay() {
      // const dayToAdd = dateHelper(this.today).format('dddd') === 'lundi' ? 2 : 1
      this.today = dateHelper(this.today).subtract(1, 'days')
    },

    nextDay() {
      // const dayToAdd =
      //   dateHelper(this.today).format('dddd') === 'samedi' ? 2 : 1
      this.today = dateHelper(this.today).add(1, 'days')
    },

    close() {
      this.showModal = false
    },

    edit(e) {
      this.event = Object.assign({}, e)
      this.$nextTick(() => (this.showModal = true))
    },

    async update(event) {
      await this.updateEvent(event)

      this.close()
    },

    error(error) {
      this.setToaster({
        message: `Une erreur est survenue : ${error}`,
        type: 'error',
        time: Date.now()
      })
    },

    screenshot() {
      const container = document.querySelector('#calendar')
      const buttons = document.querySelector('.admin__buttons')
      const labels = document.querySelectorAll('.calendar__user')

      container.style.width = '850px'
      container.style.height = '850px'
      container.style.padding = '20px'

      buttons.style.display = 'none'
      labels.forEach(l => (l.style.display = 'none'))

      html2canvas(container).then(canvas => {
        const url = canvas.toDataURL()
        const link = document.createElement('a')

        link.href = url
        link.setAttribute(
          'download',
          `${dateHelper(this.today).format('dddd-DD-MM-YYYY')}.jpg`
        )
        document.body.appendChild(link)
        link.click()

        setTimeout(() => {
          link.remove()
          container.style.width = '100%'
          container.style.height = 'auto'
          container.style.padding = '0'
          labels.forEach(l => (l.style.display = 'block'))
          buttons.style.display = 'block'
        })
      })
    }
  }
}
</script>
